<template>
  <v-container>
    <v-row class="row search spacer-md">
      <!-- Milchverwerter TSM -->
      <v-col cols="12" sm="6" md="4" lg="2" v-if="$privileges.has({ path: '/dairies', permission: 'search' })">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
      <!-- Milchverwerter -->
      <v-col cols="12" sm="6" md="4" lg="2" v-else-if="$privileges.has({ path: '/dairies', permission: 'searchManaged' })">
        <search-dairy-managed ref="search-dairy" @search="search" />
      </v-col>
      <!-- Betrieb -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
      <!-- Rechtliche Person -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <!-- Vertrag -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contract ref="search-contract" @search="search" />
      </v-col>
      <!-- Mitgliederverwaltung tbd-->
    </v-row>

    <v-btn color="primary" @click="search"><span v-html="$t('contracts_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('contracts_new_search')" /></v-btn>
    <!--    Neu-Button -->
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="new_btn" color="secondary" v-bind="props">
          <span v-html="$t('contracts_new')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!--        Neuer Vertrag-->
        <v-list-item @click="navigateToNewContract" id="new_contract_btn" v-if="$privileges.has({ path: '/contracts', permission: 'write' })">
          <v-list-item-title v-html="$t('contracts_new_contract')"></v-list-item-title>
        </v-list-item>
        <!--        Vertrag kopieren-->
        <v-list-item @click="openCopyDialog()" id="new_contract_copy_btn" v-if="$privileges.has({ path: '/contracts', permission: 'write' })">
          <v-list-item-title v-html="$t('contracts_new_mk_contract_copy')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!--&lt;!&ndash;            Download&ndash;&gt;-->
    <!--      <v-btn color="secondary" @click="download" ><span v-html="$t('contracts_download')"/></v-btn>-->
    <!--&lt;!&ndash;            Neuer Lieferant&ndash;&gt;-->
    <!--      <v-btn color="secondary" @click="navigateToNewContract" ><span v-html="$t('contracts_new_producer')"/></v-btn>-->
    <!--&lt;!&ndash;            Gruppen&ndash;&gt;-->
    <!--      <v-btn color="secondary" @click="navigateToNewContract" ><span v-html="$t('contracts_groups')"/></v-btn>-->
    <v-menu offset-y>
      <!--            Weitere Actions-->
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="further_actions_btn" color="secondary" v-bind="props">
          <span v-html="$t('contracts_further_actions')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!--            Markierte Verträge beenden-->
        <v-list-item @click="openTerminateDialog()" id="terminate_contracts" :disabled="selected.length < 1">
          <v-list-item-title v-html="$t('contracts_terminate_contracts')"></v-list-item-title>
        </v-list-item>
        <!--&lt;!&ndash;            Markierte einer Gruppe zuweisen&ndash;&gt;-->
        <!--          <v-list-item @click="someAction" id="assign_contracts_to_group">-->
        <!--            <v-list-item-title v-html="$t('contracts_assign_contracts_to_group')"></v-list-item-title>-->
        <!--          </v-list-item>-->
        <!--&lt;!&ndash;            Markierte aus Gruppe entfernen&ndash;&gt;-->
        <!--          <v-list-item @click="someAction" id="remove_contracts_from_group">-->
        <!--            <v-list-item-title v-html="$t('contracts_remove_contracts_from_group')"></v-list-item-title>-->
        <!--          </v-list-item>-->
        <!--&lt;!&ndash;            Markierte löschen&ndash;&gt;-->
        <!--          <v-list-item @click="someAction" id="delete_contracts">-->
        <!--            <v-list-item-title v-html="$t('contracts_delete_contracts')"></v-list-item-title>-->
        <!--          </v-list-item>-->
      </v-list>
    </v-menu>
    <!--    Download Button -->
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn class="button-margin-left" id="contracts_read_download_dropdown" color="secondary" v-bind="props">
          <span v-html="$t('contracts_read_download_dropdown')" />
          <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!--   Download -->
        <v-list-item @click="download" id="contracts_read_download_button" v-if="$privileges.has({ path: '/contracts', permission: 'read' })">
          <v-list-item-title v-html="$t('contracts_read_download_button')"></v-list-item-title>
        </v-list-item>
        <!--  Download Breit für TSM-->
        <v-list-item @click="downloadWide" id="contracts_read_download_wide" v-if="$privileges.has({ path: '/contracts', permission: 'read' })">
          <v-list-item-title v-html="$t('contracts_read_download_wide')"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-data-table-server
      :multi-sort="true"
      show-select
      v-model="selected"
      item-value="contractId"
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      density="compact"
    >
      <template v-slot:[`item.edit`]="{ item }">
        <div class="link" @click="openDetails(item)">
          <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
        </div>
      </template>
      <template v-slot:[`item.farmName1`]="{ item }">
        <template v-if="$privileges.has({ path: '/legalEntities', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_legal_entities_edit',
              params: { id: item.farmManagerLegalEntityId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.farmName1 }}
          </router-link>
        </template>
        <template v-else>
          {{ item.farmName1 }}
        </template>
      </template>
      <template v-slot:[`item.dairyIdent`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.dairyId, tab: 'overview' }
            }"
            class="tableaction link"
          >
            {{ item.dairyIdent }}
          </router-link>
        </template>
        <template v-else>
          {{ item.dairyIdent }}
        </template>
      </template>
      <template v-slot:[`item.validFrom`]="{ item }">
        {{ item.validFrom ? $moment(item.validFrom).format('DD.MM.YYYY') : '' }}
      </template>
      <template v-slot:[`item.validUntil`]="{ item }">
        {{ item.validUntil ? $moment(item.validUntil).format('DD.MM.YYYY') : '' }}
      </template>
      <template v-slot:[`item.farmAgisId`]="{ item }">
        <template v-if="$privileges.has({ path: '/farms', permission: 'write' })">
          <router-link
            :to="{
              name: 'masterdata_farms_edit',
              params: { id: item.farmId, tab: 'overview' }
            }"
          >
            {{ item.farmAgisId ? item.farmAgisId : $t('none') }}
          </router-link>
        </template>
      </template>
      <template v-slot:[`item.modification`]="{ item }">
        {{ (item.modifierName ? item.modifierName : '-') + ' / ' + $moment(item.modification).format('DD.MM.YYYY') }}
      </template>
    </v-data-table-server>

    <!--    Contract-Details Dialog -->
    <v-dialog :retain-focus="false" v-model="dialog" max-width="60%" id="contract-details-dialog">
      <v-card>
        <v-card-title>{{ $t('contract_details_header') + ': ' + dialogHeader }}</v-card-title>
        <v-card-text>
          <v-layout row justify-center>
            <v-overlay v-model="loading" fullscreen id="loading-dialog" content-class="loading-dialog">
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                </v-layout>
              </v-container>
            </v-overlay>
          </v-layout>
          <v-data-table-server :headers="detailsfields" :items="detailItems" :fields="detailsfields" :loading="loading" hide-default-footer>
            <template v-slot:[`item.details`]="{ item }">
              <router-link :to="{ name: 'contract_edit', params: { id: item.contractId } }" v-if="$privileges.has({ path: '/contracts', permission: 'write' })">
                {{ item.details }}
              </router-link>
            </template>
            <template v-slot:[`item.validFrom`]="{ item }">
              {{ item.validFrom ? $moment(item.validFrom).format('DD.MM.YYYY') : '' }}
            </template>
            <template v-slot:[`item.validUntil`]="{ item }">
              {{ item.validUntil ? $moment(item.validUntil).format('DD.MM.YYYY') : '' }}
            </template>
            <template v-slot:[`item.modification`]="{ item }">
              {{ (item.modifierName ? item.modifierName : '-') + ' / ' + $moment(item.modification).format('DD.MM.YYYY') }}
            </template>
          </v-data-table-server>

          <!-- History-->
          <v-col class="item card-spacing">
            <rdhComponent
              :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
              :history_show="true"
              :history_url="'/contracts/' + mainContractId"
              ref="rdh"
            ></rdhComponent>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="closeDetails()">
            <span v-html="$t('close')" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    Multi-Terminate Dialog -->
    <v-dialog :retain-focus="false" v-model="dialogTerminate" max-width="40%" id="contract-multi-termiante-dialog">
      <v-card>
        <v-card-title><span v-html="$t('contract_multi_terminate_header')" /></v-card-title>
        <v-card-text>
          <v-layout row justify-center>
            <v-overlay v-model="loadingTerminateDialog" fullscreen id="loading-terminate-dialog" content-class="loading-dialog">
              <v-container fill-height>
                <v-layout row justify-center align-center>
                  <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                </v-layout>
              </v-container>
            </v-overlay>
          </v-layout>
          <v-card-text>
            <v-alert border="top" colored-border type="info" elevation="2">
              <span v-html="$t('contract_multi_terminate_info')" />
            </v-alert>
          </v-card-text>
          <v-card-text>
            <v-checkbox v-model="terminate.closePerLatestForm" :label="$t('contract_multi_terminate_close_per_latest_form')"></v-checkbox>

            <v-row>
              <v-col sm="12" md="12" lg="6">
                <DbmMonthPicker
                  v-model="terminate.validUntil"
                  :dateLabel="$t('contract_multi_terminate_close_per_latest_form_valid_until')"
                  :clearable="true"
                  returnType="lastOfMonth"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              :loading="loadingTerminate"
              @click="terminateContracts()"
              :disabled="!(terminate.closePerLatestForm == true || terminate.validUntil)"
            >
              <span v-html="$t('contract_multi_save')" />
            </v-btn>
            <v-btn color="secondary" :loading="loading" @click="closeTerminateDialog()">
              <span v-html="$t('contracts_close')" />
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--    Copy Dialog -->
    <v-dialog :retain-focus="false" v-model="dialogCopy" max-width="640px" id="contract-multi-copy-dialog">
      <v-form ref="copyContractsForm" @submit.prevent="copyContracts()" id="copyContractsForm">
        <v-card>
          <v-card-title><span v-html="$t('contract_multi_copy_header')" /></v-card-title>
          <v-card-text>
            <!-- MBH-ID -->
            <h2 v-html="$t('contract_multi_copy_dairy_ident')"></h2>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field
                  id="contract_multi_copy_dairy_ident"
                  :rules="[$rules.required]"
                  v-model="copy.dairyIdent"
                  type="number"
                  :label="$t('contract_multi_copy_dairy_ident')"
                />
              </v-col>
            </v-row>
            <!--   Stichtag  -->
            <h2 v-html="$t('contract_multi_copy_stichtag')"></h2>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <DbmMonthPicker
                  v-model="copy.dairyContractDate"
                  :rules="[$rules.required]"
                  :dateLabel="$t('contract_multi_copy_stichtag')"
                  :clearable="true"
                  returnType="firstOfMonth"
                />
              </v-col>
            </v-row>
            <!-- Target MBH-ID -->
            <h2 v-html="$t('contract_multi_copy_target_dairy_ident')"></h2>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field
                  id="contract_multi_copy_target_dairy_ident"
                  :rules="[$rules.required]"
                  v-model="copy.targetDairyIdent"
                  type="number"
                  :label="$t('contract_multi_copy_target_dairy_ident')"
                />
              </v-col>
            </v-row>
            <!--   Start Datum -->
            <h2 v-html="$t('contract_multi_copy_start_date')"></h2>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <DbmMonthPicker
                  v-model="copy.targetDairyStartDate"
                  :rules="[$rules.required]"
                  :dateLabel="$t('contract_multi_copy_start_date')"
                  :clearable="true"
                  returnType="firstOfMonth"
                />
              </v-col>
            </v-row>
            <!-- Gehaltswerte  -->
            <v-switch id="contract_copy_content_value" v-model="copyContentValue" color="primary" :label="$t('contract_copy_content_value')"></v-switch>
            <!-- Lieferantennummer  -->
            <v-switch id="contract_copy_supplier_number" v-model="copySupplierNumber" color="primary" :label="$t('contract_copy_supplier_number')"></v-switch>
            <!-- Sammelstelle  -->
            <v-switch
              id="contract_copy_collection_point"
              v-model="copyCollectionPoint"
              color="primary"
              :label="$t('contract_copy_collection_point')"
            ></v-switch>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" :loading="loadingCopy" @click="copyContracts()">
              <span v-html="$t('contract_copy')" />
            </v-btn>
            <v-btn color="secondary" :loading="loading" @click="closeCopyDialog()">
              <span v-html="$t('contracts_close')" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import { apiURL, emitter } from '@/main'
import { Term } from '@/services/term'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import { fileCreator, showError } from '@/services/axios'
import searchContract from '@/components/searchCards/searchContract.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import searchDairyManaged from '@/components/searchCards/searchDairyManaged.vue'
import { useSearchDairyManagedStore } from '@/store/SearchDairyManagedStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import rdhComponent from '@/components/rdh/rdhComponent.vue'
import { DTSHeaders } from '@/services/BackendService'
import { useSearchContractStore } from '@/store/SearchContractStore'
import type { paths, components } from '@/lib/api/v1.d.ts'
type ContractSearchDTO = components['schemas']['ContractSearchDTO']
type ContractDetailsDTO = components['schemas']['ContractDetailsDTO']
type contactSearchParams = paths['/contracts/search']['parameters']

// http://localhost:8080/de/contracts/read

const defaultCopyValues = {
  dairyIdent: null,
  dairyContractDate: null,
  targetDairyIdent: null,
  targetDairyStartDate: null
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'contracts_read',
  data() {
    return {
      selected: [],
      items: [] as ContractSearchDTO[],
      terminate: [] as ContractDetailsDTO[],
      copy: { ...defaultCopyValues },
      dialog: false,
      dialogTerminate: false,
      dialogCopy: false,
      dialogHeader: '',
      detailItems: [] as ContractDetailsDTO[],
      mainContractId: 0,
      loading: false,
      loadingTerminateDialog: false,
      loadingCopy: false,
      loadingTerminate: false,
      initialized: false,
      copyContentValue: false,
      copySupplierNumber: false,
      copyCollectionPoint: false,

      fields: [
        {
          title: '',
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        {
          title: this.$t('contracts_agisid'),
          key: 'farmAgisId',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('contracts_info'),
          key: 'farmTypeCode',
          align: 'left',
          sortable: true
        },
        {
          title: this.$t('contracts_number'),
          key: 'number',
          align: 'left',
          sortable: true
        },
        // {
        //   title: this.$t('contracts_group'),
        //   key: 'group',
        //   sortable: false
        // },
        {
          title: this.$t('contracts_farm_address_name1'),
          key: 'farmName1',
          sortable: true
        },
        {
          title: this.$t('contracts_farm_address_name2'),
          key: 'farmName2',
          sortable: true
        },

        {
          title: this.$t('contracts_farm_address_zip'),
          key: 'farmAddressZip',
          align: 'left',
          sortable: true
        },
        {
          title: this.$t('contracts_farm_address_locality'),
          key: 'farmAddressLocality',
          sortable: true
        },
        {
          title: this.$t('contracts_animal_type'),
          key: 'animalType',
          sortable: true
        },
        {
          title: this.$t('contracts_encashment'),
          key: 'encashment',
          sortable: true
        },
        // {
        //   title: this.$t('contracts_milk_subsidy'),
        //   key: 'milkSubsidy',
        //   sortable: true
        // },
        {
          title: this.$t('contracts_mbh_ident'),
          key: 'dairyIdent',
          sortable: true,
          align: 'left'
        },

        {
          title: this.$t('contracts_dairy_address_name1'),
          key: 'dairyAddressName1',
          sortable: true
        },
        {
          title: this.$t('contracts_dairy_address_name2'),
          key: 'dairyAddressName2',
          sortable: true
        },
        {
          title: this.$t('contracts_valid_from'),
          key: 'validFrom',
          sortable: true
        },
        {
          title: this.$t('contracts_valid_until'),
          key: 'validUntil',
          sortable: true
        },
        {
          title: this.$t('contracts_last_modified'),
          key: 'modification',
          sortable: true
        }
      ] as DTSHeaders,
      detailsfields: [
        {
          title: this.$t('contract_details'),
          key: 'details'
        },
        {
          title: this.$t('contract_details_name'),
          key: 'name'
        },
        {
          title: this.$t('contract_details_value'),
          key: 'value',
          align: 'left'
        },
        {
          title: this.$t('contract_details_validFrom'),
          key: 'validFrom'
        },
        {
          title: this.$t('contract_details_validUntil'),
          key: 'validUntil'
        },
        {
          title: this.$t('contract_details_last_modification'),
          key: 'modification'
        }
      ] as DTSHeaders,
      totalElements: 0
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchDairyManagedStore(), useSearchDairyManagedStore().items),
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items)
      ]
    },
    params() {
      return {
        contractDate: useSearchContractStore().contractDate,
        validFrom: useSearchContractStore().validFrom,
        status: useSearchContractStore().status,
        validUntil: useSearchContractStore().validUntil,
        producerNumber: useSearchContractStore().producerNumber,
        animalTypeId: useSearchContractStore().animalTypeId,
        encashmentOrganisationAgisId: useSearchContractStore().encashmentOrganisationAgisId
      }
    },
    query(): contactSearchParams {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions()
      }
    }
  },
  components: {
    searchContract,
    searchLegalEntity,
    searchFarm,
    searchDairy,
    searchDairyManaged,
    DbmMonthPicker,
    rdhComponent
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async download() {
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/contracts/search', {
          // adjust endpoint
          params: { ...this.query },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(response, 'vertreage.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loading = false
      }
    },
    async downloadWide() {
      console.log('downloadWide')
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/contracts/wide/search', {
          // adjust endpoint
          params: { ...this.query },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'vertreageBreit.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loading = false
      }
    },
    openDetails(item: ContractSearchDTO) {
      this.dialog = true
      this.mainContractId = item.contractId
      this.dialogHeader =
        item.farmAgisId + (item.dairyAddressName1 ? ', ' + item.dairyAddressName1 : '') + (item.dairyAddressName2 ? ', ' + item.dairyAddressName2 : '')
      this.loadContractDetails(item)
    },
    closeDetails() {
      this.dialog = false
    },
    async load() {
      this.loading = true
      try {
        this.items = [] as ContractSearchDTO[]
        const response = await this.axios.get(apiURL + '/contracts/search', {
          params: this.query
        })
        const items = (await response.data.content) as ContractSearchDTO[]
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    async loadContractDetails(detailItem: ContractSearchDTO) {
      this.loading = true
      try {
        this.detailItems = []
        const response = await this.axios.get(apiURL + '/contracts/details', {
          params: {
            dairyId: detailItem.dairyId,
            farmId: detailItem.farmId,
            animalTypeId: detailItem.animalTypeId,
            dueDate: detailItem.validFrom
          }
        })
        const detailItems = response.data as ContractDetailsDTO[]
        this.detailItems = Array.isArray(detailItems) ? detailItems : []
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    navigateToNewContract() {
      this.$router
        .push({
          name: 'contracts_create'
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    openTerminateDialog() {
      this.dialogTerminate = true
    },
    closeTerminateDialog() {
      this.dialogTerminate = false
      this.selected = []
      this.terminate = []
    },
    async terminateContracts() {
      this.loadingTerminate = true
      try {
        const response = await this.axios.post(
          apiURL + '/contracts/terminate',
          {},
          {
            params: {
              contractIds: this.selected,
              closePerLatestForm: this.terminate.validUntil ? false : true,
              monthYear: this.terminate.validUntil
            },
            headers: { 'Content-Type': 'application/json' }
          }
        )
        if (response.status === 200) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.closeTerminateDialog()
        }
      } catch (e) {
        /* duplicated route */
        showError(e)
      } finally {
        this.loadingTerminate = false
      }
    },
    openCopyDialog() {
      this.dialogCopy = true
    },
    closeCopyDialog() {
      this.dialogCopy = false
      this.selected = []
      this.copy = { ...defaultCopyValues }
    },
    async copyContracts() {
      if (!(await this.$refs.copyContractsForm.validate()).valid) {
        return
      }
      this.loadingCopy = true
      try {
        const response = await this.axios.post(
          apiURL + '/contracts/copy',
          {},
          {
            params: {
              dairyIdent: this.copy.dairyIdent,
              dairyContractDate: this.copy.dairyContractDate,
              targetDairyIdent: this.copy.targetDairyIdent,
              targetDairyStartDate: this.copy.targetDairyStartDate,
              copyContentValue: this.copyContentValue,
              copySupplierNumber: this.copySupplierNumber,
              copyCollectionPoint: this.copyCollectionPoint
            },
            headers: { 'Content-Type': 'application/json' }
          }
        )
        if (response.status === 200) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.closeCopyDialog()
        }
      } catch (e) {
        /* duplicated route */
        showError(e)
      } finally {
        await this.load()
        this.loadingCopy = false
      }
    },

    reset() {
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-farm'].reset()
      this.$refs['search-dairy'].reset()
      this.$refs['search-contract'].reset()
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initialized = true
      if (this.$route.query.size) this.load()
    })
  }
})
</script>
